<template>
  <div v-if="hasResource('activation_code_application_management')">
    <div class="search-form">
      <application-search></application-search>
    </div>
    <application-list ref="applicationList" @handleApproval="handleApproval"></application-list>
    <approval-dialog :updateList="updateList" ref="approvalApplication"></approval-dialog>
  </div>
</template>

<script>
import {
  applicationSearch,
  applicationList,
  approvalDialog,
} from "./components/index";
export default {
  name: "applicationManagement",
  components: { applicationSearch, applicationList, approvalDialog },
  data() {
    return {};
  },
  methods: {
    handleApproval(val) {
      this.$nextTick(() => {
        this.$refs.approvalApplication.show(val);
      });
    },
    updateList() {
      this.$nextTick(() => {
        this.$refs.applicationList.init();
      });
    },
  },
};
</script>