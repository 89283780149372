<template>
  <div class="dis-fix">
    <el-form :inline="true" ref="applicationFilter" :model="queryForm">
      <el-form-item label="申请用户" prop="user">
        <el-input placeholder="输入申请用户名称查询" v-model="queryForm.user"></el-input>
      </el-form-item>
      <el-form-item label="申请邮箱" prop="email">
        <el-input placeholder="请输入申请邮箱查询" v-model="queryForm.email"></el-input>
      </el-form-item>
      <el-form-item label="申请时间" prop="time">
        <el-date-picker
          :picker-options="datePick"
          v-model="queryForm.time"
          style="width:168px"
          format="yyyy.MM.dd"
          type="daterange"
          range-separator="-"
          :maxTime="today"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="请选择"
          end-placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="申请状态" prop="status">
        <el-select v-model="queryForm.status">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="激活码" prop="code">
        <el-input placeholder="请输入激活码查询" v-model="queryForm.code"></el-input>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <span class="serach-button" @click="send">查询</span>
      <span class="clear-button" @click="clearSearch">清空</span>
    </div>
  </div>
</template>

<script>
import bus from "@/mixins/bus";
export default {
  data() {
    var disableEndDate = (time) => {
      let _now = Date.now();
      //大于当前的禁止
      return time.getTime() > _now;
    };
    return {
      queryForm: {
        user: "",
        email: "",
        time: [],
        status: "",
        code: "",
      },
      status: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 0,
          label: "待处理",
        },
        {
          value: 1,
          label: "通过",
        },
        {
          value: 2,
          label: "未通过",
        },
      ],
      today: new Date(),
      datePick: {
        disabledDate(time) {
          return disableEndDate(time);
        },
      },
    };
  },
  methods: {
    send() {
      const form = {
        activation_code: this.queryForm.code,
        status: this.queryForm.status,
        username: this.queryForm.user,
        email: this.queryForm.email,
        created_at: {
          from:
            this.queryForm.time && this.queryForm.time.length > 1
              ? this.queryForm.time[0]
              : "",
          to:
            this.queryForm.time && this.queryForm.time.length > 1
              ? this.queryForm.time[1].split(" ")[0] + " 23:59:59"
              : "",
        },
      };
      bus.$emit("makeFilter", form);
    },
    clearSearch() {
      this.clearForm("applicationFilter");
    },
  },
};
</script>