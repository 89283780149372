
<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="审核申请"
      :visible.sync="dialogVisible"
      width="479px"
      :modal-append-to-body="false"
      class="pop-dialog promo-add-dialog"
    >
      <el-form
        ref="approvalForm"
        :model="approvalForm"
        label-width="100px"
        label-position="left"
        :rules="applicationRules"
      >
        <el-form-item label="申请用户">
          <div>{{ approvalForm.username }}</div>
        </el-form-item>
        <el-form-item label="申请邮箱">
          <div>{{ approvalForm.email }}</div>
        </el-form-item>
        <el-form-item label="审核结果" required>
          <el-radio-group v-model="approvalForm.reslutOption">
            <el-radio
              v-for="(item, index) in reslutOption"
              :label="item.value"
              :key="index"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <div v-if="approvalForm.reslutOption === 1">
          <el-form-item label="激活码名称" prop="activationName">
            <el-input
              v-model="approvalForm.activationName"
              style="width: 240px"
              class="dialog-input"
              :validate-event="false"
              placeholder="输入激活码名称"
            />
          </el-form-item>
          <el-form-item label="支持设备数" required prop="activationLimit">
            <el-input
              v-model="approvalForm.activationLimit"
              style="width: 240px"
              class="dialog-input"
              :validate-event="false"
              placeholder="输入支持设备数量"
            />
          </el-form-item>
          <el-form-item label="发送通知" required>
            <el-radio-group v-model="approvalForm.notificationOption">
              <el-radio
                v-for="(item, index) in notificationOption"
                :label="item.value"
                :key="index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item class="message-style">
            <span class="message-label">
              <span style="color: red">*&nbsp;</span>发送通知
            </span>
            <el-radio-group
              v-model="approvalForm.notificationOption"
              @change="handleClear"
            >
              <el-radio
                v-for="(item, index) in noPassOption"
                :label="item.value"
                :key="index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="驳回原因"
            v-if="
              approvalForm.notificationOption !== 3 &&
              approvalForm.notificationOption === 1
            "
            prop="dismissText"
          >
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入驳回原因"
              v-model="approvalForm.dismissText"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="驳回原因"
            v-if="
              approvalForm.notificationOption !== 3 &&
              approvalForm.notificationOption === 2
            "
            prop="dismissTextEn"
          >
            <el-input
              type="textarea"
              :rows="4"
              placeholder="Please input rejection reason"
              v-model="approvalForm.dismissTextEn"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="confirm-button"
          size="medium"
          @click="sure"
          v-loading="btnLoading"
          >确认</el-button
        >
        <el-button class="cancel-button" @click="hide">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/inception-key";
import formValidations from "@/mixins/form-validations";
export default {
  props: {
    updateList: {
      type: Function,
      default: () => {
        return Function();
      },
    },
  },
  mixins: [formValidations],
  data() {
    return {
      dialogVisible: false,
      approvalForm: {
        username: "",
        email: "",
        reslutOption: 1,
        activationName: "",
        activationLimit: "",
        notificationOption: 1,
        dismissText: "",
        dismissTextEn: "",
      },
      id: "",
      reslutOption: [
        {
          name: "通过",
          value: 1,
        },
        {
          name: "不通过",
          value: 2,
        },
      ],
      notificationOption: [
        { name: "中文", value: 1 },
        { name: "英文", value: 2 },
      ],
      noPassOption: [
        { name: "中文", value: 1 },
        { name: "英文", value: 2 },
        { name: "不发送", value: 3 },
      ],
      btnLoading:false
    };
  },
  watch: {
    "approvalForm.reslutOption": function (val, oldVal) {
      if (val !== oldVal) {
        this.$refs.approvalForm.clearValidate();
      }
    },
  },
  methods: {
    clear() {
      this.approvalForm.reslutOption = 1;
      this.approvalForm.notificationOption = 1;
      this.approvalForm.activationName = "";
      this.approvalForm.activationLimit = "";
      this.dismissText = "";
    },
    show(item) {
      this.id = item.id;
      this.approvalForm.username = item.username;
      this.approvalForm.email = item.email;
      this.dialogVisible = true;
      this.clearForm("approvalForm");
    },
    hide() {
      this.clear();
      this.$refs.approvalForm.clearValidate();
      this.dialogVisible = false;
    },
    sure() {
      this.validateForm("approvalForm", () => this.update());
    },
    handleClear() {
      this.$refs.approvalForm.clearValidate();
    },
    update() {
      this.btnLoading=true;
      let params = {};
      if (this.approvalForm.reslutOption === 1) {
        params = {
          application_id: this.id,
          approve: 1,
          activation_code_name: this.approvalForm.activationName,
          activation_code_device_limit: this.approvalForm.activationLimit,
          does_notify: 1,
          notify_language:
            this.approvalForm.notificationOption === 1 ? "CN" : "EN",
        };
      }
      if (this.approvalForm.reslutOption === 2) {
        params = {
          application_id: this.id,
          approve: 0,
          does_notify: this.approvalForm.notificationOption === 3 ? 0 : 1,
          notify_language:
            this.approvalForm.notificationOption === 1 ? "CN" : "EN",
          disapprove_note:
            this.approvalForm.notificationOption === 1
              ? this.approvalForm.dismissText
              : this.approvalForm.dismissTextEn,
        };
      }
      api.reviewApplication(params).then((res) => {
        if (res.data.code === 10200) {
          this.updateList();
          this.hide();
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading=false
      });
    },
  },
};
</script>
<style lang="less">
@import "./dialog.less";
</style>