<template>
  <div>
    <div>
      <el-table
        :data="listData"
        style="width: 100%"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="tableLoading"
        class="data-table"
        ref="promoTable"
      >
        <el-table-column
          label="编号"
          prop="id"
          width="120px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="申请用户"
          prop="username"
          width="150px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.username || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="申请邮箱"
          prop="email"
          width="250px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.email || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="国家"
          prop="customer_country"
          width="150px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="公司"
          prop="customer_company"
          width="150px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="行业"
          prop="customer_industry"
          width="150px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="申请时间"
          prop="created_at"
          width="180px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="激活码" width="160px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.activation_code || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="申请状态"
          prop="status"
          width="100px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">待处理</span>
            <span v-if="scope.row.status === 1">通过</span>
            <span v-if="scope.row.status === 2">未通过</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          min-width="200px"
          align="left"
        >
          <!-- 条目操作 -->
          <template slot-scope="scope">
            <div class="icon-btn">
              <el-button
                size="mini"
                type="text"
                v-if="
                  hasResource('activation_code_update') &&
                  scope.row.status === 0
                "
                @click="handleApproval(scope.row)"
              >
                <i class="iconfont">&#xe65c;</i> 审核申请
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/inception-key";
import bus from "@/mixins/bus";
export default {
  data() {
    return {
      listData: [],
      total: 0,
      filter: {},
      tableLoading: false,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    queryInfo() {
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
      };
      return query;
    },
  },
  methods: {
    init() {
      this.getApplicationList();
    },
    getApplicationList() {
      this.tableLoading = true;
      api.applicationList(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listData = res.data.data.items;
          this.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    handleApproval(row) {
      this.$emit("handleApproval", row);
    },
  },
  mounted() {
    this.init();
  },
  created() {
    bus.$on("makeFilter", (val) => {
      this.filter = val;
      this.currentPage = 1;
      this.init();
    });
  },
};
</script>